import { Logger } from "@/utils/logger";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";

export const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },

  delay: {
    initial: 300,
    jitter: true,
    max: Number.POSITIVE_INFINITY,
  },
});

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    for (const { message, path } of graphQLErrors) {
      Logger.error(
        `[GraphQL error]: Message: ${message}, Path: ${path}, OperationName: ${
          operation.operationName
        }, OperationVariables: ${JSON.stringify(operation.variables)}`,
      );
    }
  }

  if (networkError) {
    Logger.error(
      `[Network error]: ${networkError}, OperationName: ${
        operation.operationName
      }, OperationVariables: ${JSON.stringify(operation.variables)}`,
    );
  }
});
